var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "templateShow" },
    [
      _vm.personIds && _vm.personIds.length > 0
        ? _c(
            "Card",
            [
              _c(
                "Tabs",
                {
                  attrs: { size: "small", value: _vm.tabsClickIndex },
                  on: { "on-click": _vm.tabsClick },
                },
                [
                  !_vm.loading && !_vm.typeStatus && _vm.isShowReport
                    ? _c(
                        "TabPane",
                        { attrs: { label: "个人体检报告", name: "0" } },
                        [
                          _c("div", { staticClass: "previewIframe" }, [
                            _c("iframe", {
                              ref: "previewHtmlTemplate",
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: _vm.previewHtmlUrl,
                                frameborder: "0",
                              },
                            }),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  !_vm.loading && _vm.typeStatus
                    ? _c(
                        "TabPane",
                        { attrs: { label: "复查报告", name: "3" } },
                        [
                          _c("div", { staticClass: "previewIframe" }, [
                            _c("iframe", {
                              ref: "previewHtmlTemplateFC",
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: _vm.previewHtmlUrlFC,
                                frameborder: "0",
                              },
                            }),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._l(_vm.tabPanes, function (item, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticStyle: { display: "none" },
                        attrs: { label: item.name },
                      },
                      [
                        item.id != "name11"
                          ? _c("preview-print", {
                              ref: "previewPrint",
                              refInFor: true,
                              attrs: {
                                "preview-id": "preview" + i,
                                "group-item-data": _vm.tabItems,
                                "depart-item-results": _vm.departItemResults,
                                "t-template-id": item.templateId,
                                "base-project-id": item.baseProjectId,
                                "depart-result-id": item.departResultId,
                                "group-item-id": item.groupItemId,
                                "person-id": _vm.personId,
                                "person-info": _vm.personInfo,
                                "t-template-type": item.type,
                                checkSign: item.checkSign,
                                healthCheckup: item.healthCheckup,
                                name: item.name,
                                isShow: item.isShow,
                                opinion: item.opinion,
                                inspectResult: item.inspectResult,
                              },
                              on: { closeLoading: _vm.closeLoading },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "Card",
            [
              _c("no-data", {
                staticStyle: {
                  height: "calc(100vh - 83px)",
                  overflow: "hidden",
                  display: "flex",
                  "flex-direction": "row",
                  "justify-content": "center",
                },
                attrs: { width: "150" },
              }),
            ],
            1
          ),
      _c(
        "Spin",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.ptfLoading,
              expression: "ptfLoading",
            },
          ],
          attrs: { fix: "" },
        },
        [
          _c("div", { staticClass: "demo-spin-icon-load" }, [
            _c("i", { staticClass: "iconfont icon-loader--line" }),
          ]),
          _c("div", { staticStyle: { "font-size": "16px" } }, [
            _vm._v("Loading..."),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }